//Days
export const Days = Object.freeze([
    {date: '2019/11/10', resort: 'Lake Louise'},
    {date: '2019/11/15', resort: 'Eldora'},
    {date: '2019/11/16', resort: 'Eldora'},
    {date: '2019/11/17', resort: 'Copper Mountain'},
    {date: '2019/11/18', resort: 'Keystone'},
    {date: '2019/11/19', resort: 'Vail'},
    {date: '2019/11/20', resort: 'Breckenridge'},
    {date: '2019/11/21', resort: 'Copper Mountain'},
    {date: '2019/11/22', resort: 'Breckenridge'},
    {date: '2019/11/25', resort: 'Keystone'},
    //!0 days up to here
    {date: '2019/11/26', resort: 'Keystone'},
    {date: '2019/11/27', resort: 'Breckenridge'},
    {date: '2019/11/28', resort: 'Winter Park'},
    {date: '2019/12/05', resort: 'Park City'},
    {date: '2019/12/06', resort: 'Park City'},
    {date: '2019/12/09', resort: 'Heavenly'},
    {date: '2019/12/10', resort: 'Kirkwood'},
    {date: '2019/12/11', resort: 'Kirkwood'},
    {date: '2019/12/13', resort: 'Squaw Valley'},
    {date: '2019/12/14', resort: 'Squaw Valley'},
    //20 days up to here
    {date: '2019/12/15', resort: 'Alpine Meadows'},
    {date: '2019/12/16', resort: 'Northstar'},
    {date: '2019/12/17', resort: 'Alpine Meadows'},
    {date: '2019/12/19', resort: 'June Mountain'},
    {date: '2019/12/20', resort: 'Mammoth Mountain'},
    {date: '2019/12/21', resort: 'Mammoth Mountain'},
    {date: '2019/12/23', resort: 'Mammoth Mountain'},
    {date: '2019/12/24', resort: 'Mammoth Mountain'},
    {date: '2019/12/25', resort: 'Alpine Meadows'},
    {date: '2020/01/03', resort: 'Kirkwood'},
    //30 days up to here
    {date: '2020/01/04', resort: 'Heavenly'},
    {date: '2020/01/06', resort: 'June Mountain'},
    {date: '2020/01/07', resort: 'Mammoth Mountain'},
    {date: '2020/01/08', resort: 'Mammoth Mountain'},
    {date: '2020/01/14', resort: 'Park City'},
    {date: '2020/01/15', resort: 'Deer Valley'},
    {date: '2020/01/16', resort: 'Deer Valley'},
    {date: '2020/01/17', resort: 'Snowbasin'},
    {date: '2020/01/18', resort: 'Snowbasin'},
    {date: '2020/01/21', resort: 'White Pass Ski Area'},
    //40 days up to here
    {date: '2020/01/22', resort: 'White Pass Ski Area'},
    {date: '2020/01/25', resort: 'Crystal Mountain'},
    {date: '2020/01/26', resort: 'Crystal Mountain'},
    {date: '2020/01/27', resort: 'Crystal Mountain'},
    {date: '2020/01/28', resort: 'Crystal Mountain'},
    {date: '2020/01/30', resort: 'Whistler Blackcomb'},
    {date: '2020/01/31', resort: 'Whistler Blackcomb'},
    {date: '2020/02/02', resort: 'Whistler Blackcomb'},
    {date: '2020/02/05', resort: 'Apex'},
    {date: '2020/02/06', resort: 'Apex'},
    //50 days up to here
    {date: '2020/02/08', resort: 'Revelstoke'},
    {date: '2020/02/10', resort: 'Revelstoke'},
    {date: '2020/02/12', resort: 'Kimberley'},
    {date: '2020/02/13', resort: 'Fernie'},
    {date: '2020/02/14', resort: 'Fernie'},
    {date: '2020/02/16', resort: 'Fernie'},
    {date: '2020/02/18', resort: 'Castle Mountain'},
    {date: '2020/02/20', resort: 'Silver Mountain'},
    {date: '2020/02/21', resort: 'Silver Mountain'},
    {date: '2020/02/23', resort: 'Stevens Pass'},
    //60 days up to here
    {date: '2020/02/24', resort: 'Stevens Pass'},
    {date: '2020/02/25', resort: 'Stevens Pass'},
    {date: '2020/02/27', resort: 'Big Sky'},
    {date: '2020/02/28', resort: 'Big Sky'},
    {date: '2020/03/02', resort: 'Winter Park'},
    {date: '2020/03/03', resort: 'Winter Park'},
    {date: '2020/03/04', resort: 'Breckenridge'},
    {date: '2020/03/06', resort: 'Copper Mountain'},
    {date: '2020/03/10', resort: 'Keystone'},
    {date: '2020/03/11', resort: 'Copper Mountain'},
    //70 days up to here
    {date: '2020/03/12', resort: 'A-Basin'},
])